import styled from 'styled-components';
import colors from '@shared/colors';
import { generateBreakpoint } from '../../layouts/grid';

export const StyledTags = styled.div``;

export const Tag = styled.div`
    display: inline-block;
    border-radius: 19px;
    border: solid 1px ${colors.white};
    ${props =>
        props.color
            ? `border-color: ${props.color};
        color: ${props.color};`
            : ''}
    ${props =>
        props.isActive && props.selectedColor
            ? `border-color: ${props.selectedColor};
        color: ${props.selectedColor};`
            : ''}

    text-transform: uppercase;

    font-size: 12px;
    line-height: 1.21;

    padding: 8px 14px;
    margin-bottom: 5px;
    margin-right: 5px;

    ${generateBreakpoint('md')(
        `font-size: 14px;
    line-height: 1.21;

    padding: 11px 19px;
    margin-bottom: 7px;
    margin-right: 7px;`,
    )}

    user-select: none;
`;
