import styled from 'styled-components';
import { generateBreakpoint } from '@layouts/grid';

export const StyledShowDetailInfo = styled.div``;

export const Description = styled.div`
    font-family: 'SuisseIntl';

    font-size: 12px;
    line-height: 1.42;
    letter-spacing: 0.24px;
    padding-bottom: 16px;

    ${generateBreakpoint('md')(
        `font-size: 14px;
    line-height: 1.21;
    letter-spacing: 0.28px;
    padding-bottom: 32px;`,
    )}
`;
