import styled from 'styled-components';
import { generateBreakpoint } from '../../layouts/grid';

export const SocialLinksSt = styled.div`
    font-size: 10px;
    line-height: 1.6;

    text-transform: uppercase;
    margin-bottom: 15px;
    word-break: break-word;

    ${generateBreakpoint('md')(
        `font-size: 14px;
        line-height: 1.71;
        margin-bottom: 30px;`,
    )}
`;

export const SocialLinkSt = styled.div`
    span {
        padding-right: 10px;
    }
`;
