import React, { useEffect, useState, createRef } from 'react';
import { get } from 'lodash';
import { Link } from 'gatsby';
import { RichText } from '@components/shared';
import { formatDateHeader, formatTime } from '@src/helpers';
import ShowInfo from './show-info';
import {
    DateBlock,
    DateHeader,
    ShowMoreButton,
    StyledGroupContainer,
    StyledShow,
    StyledShowTitle,
    StyledTime,
    StyledUpcomingShows,
    Title,
    WrapLink,
} from './index.styled';
import { ShowInfoMobileWrapper } from './show-info.styled';
import { useLazy } from '../../helpers/hooks';
import { Helmet } from 'react-helmet';

const generateSEOSchema = show => {
    const seoData = {
        '@context': 'http://schema.org',
        '@type': 'MusicEvent',
        location: {
            '@type': 'MusicVenue',
            name: 'Future Intel Radio',
            sameAs: 'https://futureintelradio.com',
            address: 'The Hague, The Netherlands',
        },
        name: get(show, 'title[0].text', ''),
        performer: get(show, 'title[0].text', ''),
        description: get(show, 'description[0].text', ''),
        image: get(
            show,
            'show_imageSharp.childImageSharp.fluid.src',
            get(show, 'show_image.url', ''),
        ),
        eventStatus: 'https://schema.org/EventScheduled',
        eventAttendanceMode: 'https://schema.org/OnlineEventAttendanceMode',
        organizer: {
            '@type': 'Organization',
            name: 'Future Intel',
            url: 'https://futureintelradio.com',
        },
        startDate: show.date_start,
        endDate: show.date_end,
        url: `https://futureintelradio.com/schedule/${show.uid}`,
    };
    return JSON.stringify(seoData);
};

const WrapInLink = ({ onClick, uid, children }) =>
    !!onClick ? (
        <WrapLink
            href={`/schedule/${uid}`}
            onClick={e => {
                e.preventDefault();
                onClick();
            }}
        >
            {children}
        </WrapLink>
    ) : (
        children
    );

const Show = ({ show, activeSlug, onClick, isMobile, direction }) => {
    const {
        date_start,
        date_end,
        title,
        _meta: { uid },
    } = show;

    const isActive = activeSlug === uid;
    return (
        <div>
            {isActive ? (
                <Helmet>
                    <script type="application/ld+json">
                        {generateSEOSchema(show)}
                    </script>
                </Helmet>
            ) : null}
            <WrapInLink uid={uid} onClick={onClick}>
                <StyledShow hasOnClick={!!onClick} isActive={isActive}>
                    <StyledTime>
                        {formatTime(date_start)}-{formatTime(date_end)}
                    </StyledTime>
                    <StyledShowTitle direction={direction} isActive={isActive}>
                        <div className="rt-wrapper">
                            <RichText render={title} />
                        </div>
                        <span>+</span>
                        <div className="active-indicator" />
                    </StyledShowTitle>
                </StyledShow>
            </WrapInLink>
            {isMobile ? (
                <ShowInfoMobileWrapper isActive={isActive}>
                    <ShowInfo
                        direction="bottom"
                        item={show}
                        onClick={onClick}
                    />
                </ShowInfoMobileWrapper>
            ) : null}
        </div>
    );
};

const UpcomingDate = ({
    date,
    shows,
    direction,
    animateOrder,
    dateIndex,
    activeIndex,
    activeSlug,
    isMobile,
    onShowClick,
}) => {
    const dateHeader = formatDateHeader(date);
    const ref = createRef();
    const loaded = useLazy(ref);
    const [animateStart, setAnimateStart] = useState(true);
    useEffect(() => {
        if (loaded) {
            setAnimateStart(false);
        }
    }, [loaded]);
    return (
        <DateBlock
            key={date}
            ref={ref}
            isActive={dateIndex === activeIndex}
            animateStart={animateStart}
            animateOrder={animateOrder}
            direction={direction === 'left' ? 'right' : 'left'}
        >
            <DateHeader>
                <h3>
                    ↳ {dateHeader[0]}
                    <sup>{dateHeader[1]}</sup>
                </h3>
            </DateHeader>
            {shows.map(s => (
                <Show
                    key={`show_${s._meta.uid}`}
                    show={s}
                    activeSlug={activeSlug}
                    isMobile={isMobile}
                    direction={direction === 'left' ? 'right' : 'left'}
                    onClick={
                        typeof onShowClick === 'function'
                            ? () => {
                                  onShowClick(dateIndex, s._meta.uid);
                              }
                            : null
                    }
                />
            ))}
        </DateBlock>
    );
};

// group by date
export const groupUpcomingShows = (groupedDates, maxDates, n = 1) => {
    const dates = Object.keys(groupedDates);

    return dates.slice(0, maxDates).reduce((acc, item, i) => {
        const bucketIndex = (i % 2) + parseInt(i / (2 * n)) * 2;
        if (!acc[bucketIndex]) {
            acc[bucketIndex] = {
                groupIndex: bucketIndex,
                direction: bucketIndex % 2 ? 'right' : 'left',
                dates: [],
                dateNames: [],
            };
        }
        acc[bucketIndex].dateNames.push(item);
        acc[bucketIndex].dates.push({
            date: item,
            shows: groupedDates[item],
            animateOrder: i,
        });
        return acc;
    }, []);
};

const findActiveShow = (groupedDates, slug) => {
    let activeShow = null;

    for (let d = 0; d < groupedDates.length; d++) {
        for (let i = 0; i < groupedDates[d].dates.length; i++) {
            const foundItem = (activeShow = groupedDates[d].dates[i].shows.find(
                i => i.uid === slug,
            ));
            if (foundItem) {
                break;
            }
        }
        if (activeShow) {
            break;
        }
    }
    return activeShow;
};

const GroupContainer = ({
    children,
    activeIndex,
    groupIndex,
    direction,
    isMobile,
    activeShow,
    onShowClick,
}) => {
    let isShifted = false;
    const [currentShow, setCurrentShow] = useState(null);
    if (activeIndex > -1) {
        const activeRow = parseInt(activeIndex / 2, 10);
        const groupRow = parseInt(groupIndex / 2, 10);
        const isThisRow = activeRow === groupRow;
        isShifted =
            isThisRow && 1 + groupRow * 2 - (activeIndex % 2) === groupIndex;
    }
    const ref = createRef();
    const loaded = useLazy(ref);
    const [animateStart, setAnimateStart] = useState(true);
    useEffect(() => {
        if (loaded) {
            setAnimateStart(false);
        }
    }, [loaded]);
    useEffect(() => {
        if (isShifted && !currentShow) {
            setCurrentShow(activeShow);
        } else if (!isShifted && currentShow) {
            setTimeout(() => {
                setCurrentShow(null);
            }, 500);
        }
    }, [isShifted, currentShow]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <StyledGroupContainer
            ref={ref}
            animateStart={animateStart}
            isActive={groupIndex === activeIndex}
            isShifted={!isMobile && isShifted}
            isVisible={!!currentShow}
            direction={direction}
        >
            {!isMobile && !!currentShow ? (
                <ShowInfo
                    direction={direction}
                    item={currentShow}
                    onClick={
                        typeof onShowClick === 'function'
                            ? () => onShowClick(-1, '')
                            : null
                    }
                />
            ) : null}
            {children}
        </StyledGroupContainer>
    );
};

const UpcomingShows = ({
    groupedDates = [],
    activeIndex,
    activeSlug,
    isMobile,
    onShowClick,
    showMore,
}) => {
    const activeShow = findActiveShow(groupedDates, activeSlug);
    return (
        <StyledUpcomingShows>
            <Title>\COMING UP</Title>
            {groupedDates.map(g => (
                <GroupContainer
                    key={`group_${g.groupIndex}`}
                    groupIndex={g.groupIndex}
                    direction={g.direction}
                    activeIndex={activeIndex}
                    activeSlug={activeSlug}
                    activeShow={activeShow}
                    onShowClick={onShowClick}
                    isMobile={isMobile}
                >
                    {g.dates.map((d, i) => (
                        <UpcomingDate
                            key={d.date}
                            direction={g.direction}
                            animateOrder={d.animateOrder}
                            date={d.date}
                            shows={d.shows}
                            isMobile={isMobile}
                            activeSlug={activeSlug}
                            activeIndex={activeIndex}
                            dateIndex={g.groupIndex}
                            onShowClick={onShowClick}
                        />
                    ))}
                </GroupContainer>
            ))}
            {showMore ? (
                <ShowMoreButton>
                    <Link to="/schedule">show more →</Link>
                </ShowMoreButton>
            ) : null}
        </StyledUpcomingShows>
    );
};

export default UpcomingShows;
