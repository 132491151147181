import React from 'react';
import Image from '@components/image';
import ShowDetailsInfo from '../show-details-info';

import {
    CloseIcon,
    ShowHeader,
    ShowInfoInner,
    ShowDetailsWrapper,
    ShowImageWrapper,
    StyledShowInfo,
} from './show-info.styled';

const ShowInfoRender = ({ item, direction, onClick }) => {
    const {
        description,
        social_links,
        _meta,
        show_image,
        show_imageSharp,
    } = item;

    return (
        <StyledShowInfo direction={direction}>
            <ShowHeader>
                <h3>Info</h3>
                <CloseIcon onClick={onClick} />
            </ShowHeader>
            <ShowInfoInner>
                <ShowImageWrapper>
                    {show_image ? (
                        <Image
                            image={show_image}
                            imageSharp={show_imageSharp}
                        />
                    ) : null}
                </ShowImageWrapper>
                <ShowDetailsWrapper>
                    <ShowDetailsInfo
                        description={description}
                        social_links={social_links}
                        _meta={_meta}
                    />
                </ShowDetailsWrapper>
            </ShowInfoInner>
        </StyledShowInfo>
    );
};

const ShowInfo = ({ item, direction, onClick }) => {
    if (!item) {
        return null;
    }
    return (
        <ShowInfoRender item={item} direction={direction} onClick={onClick} />
    );
};

export default ShowInfo;
