import { useState, useEffect, useLayoutEffect } from 'react';

// Snippet stolen from:
// https://usehooks.com/useMedia/

// Hook
export function useMedia(queries, values, defaultValue) {
    // Array containing a media query list for each query
    let mediaQueryLists = [];
    let effectFn = useEffect;
    // SSR-fix :-)
    if (
        typeof window !== 'undefined' &&
        typeof window.matchMedia != 'undefined'
    ) {
        effectFn = useLayoutEffect;
        mediaQueryLists = queries.map(q => window.matchMedia(q));
    }

    // Function that gets value based on matching media query
    const getValue = () => {
        // Get index of first media query that matches
        const index = mediaQueryLists.findIndex(mql => mql.matches);
        // Return related value or defaultValue if none
        return typeof values[index] !== 'undefined'
            ? values[index]
            : defaultValue;
    };

    // State and setter for matched value
    const [value, setValue] = useState(getValue);

    effectFn(
        () => {
            // Event listener callback
            // Note: By defining getValue outside of useEffect we ensure that it has ...
            // ... current values of hook args (as this hook callback is created once on mount).
            const handler = () => setValue(getValue);

            // Set a listener for each media query with above handler as callback.
            mediaQueryLists.forEach(mql => mql.addListener(handler));
            // Remove listeners on cleanup
            return () =>
                mediaQueryLists.forEach(mql => mql.removeListener(handler));
        },
        [], // Empty array ensures effect is only run on mount and unmount
    );

    return value;
}

// Snippet stolen from: (and fixed)
// https://usehooks.com/useOnScreen/

export function useOnScreen(ref, rootMargin = '0px') {
    // State and setter for storing whether element is visible

    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Update our state when observer callback fires
                setIntersecting(entry.isIntersecting);
            },
            {
                rootMargin,
            },
        );
        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            observer.unobserve(currentRef);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return isIntersecting;
}

export function useLazy(ref, callback) {
    const [loaded, setLoaded] = useState(false);

    const isOnScreen = useOnScreen(ref);

    useEffect(() => {
        if (isOnScreen) {
            setLoaded(true);
        }
    }, [isOnScreen]);

    useEffect(() => {
        if (loaded && typeof callback === 'function') {
            callback();
        }
    }, [loaded]); // eslint-disable-line react-hooks/exhaustive-deps

    return loaded;
}
