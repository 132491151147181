import React from 'react';
import { RichText } from '@components/shared';
import Tags from '@components/tags';

import { Description, StyledShowDetailInfo } from './index.styled';
import { SocialLinks } from '../shared/SocialLinks';

export const ShowDetailsInfo = ({
    description,
    social_links,
    _meta,
    isArchive,
}) => (
    <StyledShowDetailInfo isArchive={isArchive}>
        <Description>
            {description ? <RichText render={description} /> : null}
        </Description>
        <SocialLinks links={social_links} />
        <Tags tags={_meta ? _meta.tags : []} />
    </StyledShowDetailInfo>
);

export default ShowDetailsInfo;
