import React from 'react';
import Link from './links';
import { SocialLinksSt, SocialLinkSt } from './SocialLinks.styled';

const URL_REGEX = /((?:http[s]?):\/\/)?(?:([\w-]+)\.)?(([\w-]+)\.([\w-]+))(?:\/([\w.\-/]+)\b)?/;

export const parseUrl = urlObj => {
    if (!urlObj || !urlObj.url) {
        return {
            url: '',
        };
    }
    const urlMatches = urlObj.url.match(URL_REGEX);
    const linkData = {
        headline: 'invalid',
        label: 'invalid',
        url: {
            ...urlObj,
            target: '_new',
        },
    };
    if (!urlMatches) {
        return linkData;
    }
    const [, , subdomain, hostname, domain, , pathname] = urlMatches;
    linkData.headline = domain;
    switch (domain) {
        case 'instagram':
        case 'twitter':
            linkData.label = `@${pathname}`;
            break;
        case 'bandcamp':
            linkData.label = subdomain !== 'www' ? subdomain : pathname;
            break;
        case 'facebook':
        case 'mixcloud':
        case 'soundcloud':
            linkData.label = pathname;
            break;
        default:
            linkData.label = `${hostname}${pathname ? `/${pathname}` : ''}`;
            linkData.headline = 'website';
            break;
    }

    return linkData;
};

export const SocialLinks = ({ links = [], urlKey = 'link_url' }) => {
    const parsedLinks = links.map(sl => {
        return parseUrl(sl[urlKey]);
    });
    return (
        <SocialLinksSt>
            {parsedLinks
                ? parsedLinks.map((l, i) => (
                      <SocialLinkSt key={`sl_${i}`}>
                          {l.headline ? <span>{l.headline}:</span> : null}
                          {l.url ? <Link link={l.url}>{l.label}</Link> : null}
                      </SocialLinkSt>
                  ))
                : null}
        </SocialLinksSt>
    );
};
