import styled from 'styled-components';
import { generateGrid, generateCol, generateBreakpoint } from '@layouts/grid';
import crossIcon from '@src/images/icons/schedule-kruisje-pink.svg';
import {
    generateVwPercentageHeight,
    generateVwPercentageWidth,
} from '@src/helpers';

export const ShowInfoMobileWrapper = styled.div`
    position: relative;
    transition: max-height 0.4s cubic-bezier(0.645, 0.455, 0.355, 1.005);
    overflow: hidden;
    max-height: 0px;

    ${props => (props.isActive ? `max-height: 100vh;` : '')}
`;

export const ShowDetailsWrapper = styled.div`
    ${generateCol(12)}

    ${generateBreakpoint('sm')(generateCol(6))}
    ${generateBreakpoint('md')(generateCol(7))}

    ${generateBreakpoint('xl')(generateCol(6))}

    padding: 14px 0;
`;

export const ShowHeader = styled.div`
    display: none;
    ${generateBreakpoint('lg')('display: block')}

    position: relative;
    padding: 0 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    h3 {
        font-family: 'SuisseIntl';
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: 0.28px;
        padding: 7px 0;
        text-transform: uppercase;
    }
`;

export const CloseIcon = styled.div`
    position: absolute;
    right: 20px;
    top: 7px;
    height: 18px;
    width: 18px;
    background-image: url(${crossIcon});
    background-repeat: no-repeat;
    background-size: contain;

    cursor: pointer;
`;

export const StyledShowInfo = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    ${generateBreakpoint('lg')(
        `position: absolute;
        z-index: 0;
        right: 100%;
        top: 0;
        height: 200%;
        width: 100%;
        border-bottom: 0;`,
    )}

    ${props => generateBreakpoint('lg')(`${props.direction}: 100%;`)}
`;

export const ShowInfoInner = styled.div`
    ${generateGrid(12, 8)}
    padding: 4px 12px;

    ${generateBreakpoint('md')('padding: 15px 20px;')}
`;

export const ShowImageWrapper = styled.div`
    ${generateCol(12)}
    background-color: black;
    ${generateVwPercentageHeight(220, 220, 320)}
    ${generateVwPercentageWidth(220, 220, 320)}

    ${generateBreakpoint('sm')(
        `${generateCol(6)}
        ${generateVwPercentageHeight(172, 250, 400)}
        ${generateVwPercentageWidth(172, 250, 400)}`,
    )}

    ${generateBreakpoint('md')(`${generateCol(5)}`)}

    ${generateBreakpoint('lg')(
        `${generateVwPercentageHeight(172, 250, 1024)}
        ${generateVwPercentageWidth(172, 250, 1024)}`,
    )}

    ${generateBreakpoint('xl')(
        `${generateCol(6)}
        ${generateVwPercentageHeight(326, 489)}
        ${generateVwPercentageWidth(326, 489)}`,
    )}
`;
