import React from 'react';
import { StyledTags, Tag } from './index.styled';

const Tags = ({
    color,
    bgColor,
    selectedColor,
    tags = [],
    selectedTags = [],
    onClick,
}) => {
    return (
        <StyledTags>
            {tags.map(tag => (
                <Tag
                    key={`tag_${tag}`}
                    color={color}
                    bgColor={bgColor}
                    selectedColor={selectedColor}
                    isActive={selectedTags.indexOf(tag) !== -1}
                    onClick={() =>
                        typeof onClick === 'function' && onClick(tag)
                    }
                >
                    {tag}
                </Tag>
            ))}
        </StyledTags>
    );
};

export default Tags;
