import styled from 'styled-components';
import { generateGrid, generateCol, generateBreakpoint } from '@layouts/grid';
import colors from '@shared/colors';
import scheduleActiveDown from '@src/images/icons/schedule-triangle-down-pink.svg';
import scheduleActiveRight from '@src/images/icons/schedule-triangle-right-pink.svg';

export const StyledUpcomingShows = styled.div`
    background-color: ${colors.violetBlue};
    overflow: hidden;
    position: relative;
`;

export const Title = styled.h2`
    font-size: 12px;
    padding-left: 12px;
    padding-top: 24px;
    padding-bottom: 22px;

    line-height: 1.25;
    letter-spacing: 0.24px;

    ${generateBreakpoint('md')(
        `padding-top: 36px;
        padding-left: 20px;
        padding-bottom: 37px;
        font-size: 14px;
        line-height: 1.21;
        letter-spacing: 0.28px;`,
    )}
`;

export const DateHeader = styled.div`
    padding-left: 12px;

    border-top: 1px solid ${colors.brownGrey};
    border-bottom: 1px solid ${colors.brownGrey};
    background-color: ${colors.brownGrey};
    color: ${colors.violetBlue};

    h3 {
        font-family: 'SuisseIntl';

        font-size: 12px;

        line-height: 1.33;
        letter-spacing: 0.24px;

        padding: 7px 0;
        text-transform: uppercase;

        sup {
            text-transform: lowercase;
            vertical-align: super;
            font-size: 7px;
        }
    }

    ${generateBreakpoint('md')(
        `background-color: ${colors.violetBlue};
        color: ${colors.white};
        padding-left: 20px;
        
        h3 {
            font-size: 14px;
            line-height: 1.29;
            letter-spacing: 0.28px;

            sup {
                font-size: 8px;
            }
        }`,
    )}
`;

export const DateBlock = styled.div`
    position: relative;
    ${props => props.direction}: 0;
    opacity: 1;
    z-index: 2;
    padding-bottom: 40px;
    transition-property: ${props => props.direction}, opacity;
    transition-duration: 1000ms, 500ms;
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1),
        cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition-delay: ${props => props.animateOrder * 100}ms,
        ${props => props.animateOrder * 100 + 450}ms;

    ${props =>
        props.animateStart
            ? `${props.direction}: 98%;
        opacity: 0;`
            : ''}

    ${generateBreakpoint('md')(`
        padding-bottom: 64px;
    `)}
`;

export const StyledTime = styled.div`
    padding: 10px 12px;
    padding-bottom: 8px;

    font-size: 12px;
    line-height: 1.25;

    ${generateBreakpoint('md')(
        `padding: 16px;
        font-size: 14px;
        line-height: 1.21;

        border-right: 1px solid ${colors.brownGrey};
        text-align: right;`,
    )}
`;

export const StyledShowTitle = styled.div`
    text-transform: uppercase;
    font-size: 28px;
    line-height: 1.25;
    letter-spacing: -0.84px;
    padding: 0 12px;
    padding-bottom: 10px;

    span.small {
        font-size: 15px;
        vertical-align: super;
    }

    ${generateBreakpoint('md')(
        `padding: 16px;
        font-size: 40px;
        padding-left: 4px;

        line-height: 1;
        letter-spacing: -1.2px;

        span.small {
            font-size: 22px;
        }`,
    )}

    display: flex;
    justify-content: flex-start;
    align-items: center;

    div.rt-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        min-width: 0px;
        height: 35px;

        ${generateBreakpoint('md')(`height: 40px;`)}
        > p,
        h2,
        h3,
        h4 {
            transition: color 130ms cubic-bezier(0.41, 0.4, 0.595, 0.995);
            white-space: nowrap;

            &:hover {
                color: ${colors.mediumPink};
            }
        }
    }

    > span {
        position: absolute;
        right: 12px;
        font-size: 20px;
        font-weight: 300;
        line-height: 35px;
        color: ${colors.veryLightPink};
        ${props => (props.isActive ? 'display: none;' : '')}
        ${generateBreakpoint('md')(
            `font-size: 24px;
            line-height: 40px;
            right: 20px;`,
        )}
        ${generateBreakpoint('lg')('display: none;')}
    }

    > div.active-indicator {
        display: none;
        margin-left: 10px;

        height: 13px;
        width: 28px;
        background-image: url(${scheduleActiveDown});
        background-repeat: no-repeat;
        background-size: contain;

        ${generateBreakpoint('lg')(
            `height: 28px;
            width: 13px;
            margin-left: 15px;
            background-image: url(${scheduleActiveRight});`,
        )}

        ${props =>
            props.direction === 'left'
                ? `${generateBreakpoint('lg')('transform: scaleX(-1);')}`
                : ''}
    }

    ${props =>
        props.isActive
            ? `color: ${colors.mediumPink};
        > div.rt-wrapper {
            > p,
            h2,
            h3,
            h4 {
                position: absolute;
                height: 100%;
                width: 100%;
                text-align: ${props.direction};
                animation: ${
                    props.direction === 'left' ? 'marqueeLeft' : 'marqueeRight'
                } 10s linear infinite;
            }
        }
        > div.active-indicator {
            display: block;
        }`
            : `> div.rt-wrapper {
            max-width: 90vw;
            ${generateBreakpoint('md')('max-width: 60vw;')}
            ${generateBreakpoint('lg')('max-width: 31.5vw;')}
            > p,
            h2,
            h3,
            h4 {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }`}

    @keyframes marqueeLeft {
        0%   {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    @keyframes marqueeRight {
        0%   {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;

export const StyledShow = styled.div`
    ${generateGrid(6)}

    > :nth-child(1),
    > :nth-child(2) {
        ${generateCol(6)}
    }

    ${props =>
        props.isActive
            ? ''
            : 'border-bottom: 1px solid rgba(255, 255, 255, 0.5);'}

    ${generateBreakpoint('md')(
        `> :nth-child(1) {
            ${generateCol(2)};
        }
        > :nth-child(2) {
            ${generateCol(4)};
        }
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);`,
    )}
`;

export const WrapLink = styled.a``;

export const StyledGroupContainer = styled.div`
    position: relative;
    background-color: ${colors.violetBlue};
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:before,
    &:after {
        position: absolute;
        opacity: 1;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: ${colors.brownGrey};
        transition: 2000ms opacity ease-in;

        ${props => (props.animateStart ? `opacity: 0;` : '')}
    }
    &:before {
        left: -1px;
    }
    &:after {
        right: -1px;
    }

    ${props =>
        props.direction === 'left'
            ? `right: 0%;
        transition: right cubic-bezier(0.645, 0.455, 0.355, 1.005) 550ms;
        `
            : `left: 0%;
        transition: left cubic-bezier(0.645, 0.455, 0.355, 1.005) 550ms;`}

    ${props => (props.isVisible ? 'z-index: 0;' : '')};

    ${props =>
        props.isShifted && props.direction === 'left' ? 'right: 100%;' : ''}
    ${props =>
        props.isShifted && props.direction === 'right' ? 'left: 100%;' : ''}
`;

export const ShowMoreButton = styled.div`
    font-family: 'SuisseIntl';
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 0.24px;
    text-transform: uppercase;

    position: absolute;
    z-index: 10;
    bottom: 12px;
    right: 20px;

    a,
    a:visited,
    a:active {
        color: ${colors.brownGrey};
    }

    ${generateBreakpoint('md')(
        `font-size: 14px;
        line-height: 1.29;
        letter-spacing: 0.28px;

        bottom: 23px;`,
    )}
`;
